import React, {Component} from 'react';
import styles from './AboutPage.module.sass'
import facebook from './facebook-f-brands.svg'
import instagram from './instagram-brands.svg'

export default class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div className={styles.aboutContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.socialLinksContainer}>
                        <a href="https://www.instagram.com/atelier.cocktail.design/" target="_blank"><img src={instagram} alt=""/></a>
                        <a href="https://www.facebook.com/cocktaildesigngraphique/" target="_blank"><img src={facebook} alt=""/></a>
                    </div>
                    <p><b>•</b><br/>
                        cocktail<br/>
                        <b>atelier ne voulant pas se définir par lui même mais par ceux avec qui il travaille et
                            construit des projets</b><br/>

                        Chloé Serieys ¨ºº Lucie Lafitte<br/>
                        <br/>
                        <b>∆<br/>
                            1 avenue d’Ilbaritz<br/>
                            64100 Bayonne</b><br/>
                        <br/>
                        <b>*}</b> site développé par <b>Théo Serieys</b></p><br/>
                </div>
                <div className={styles.rightContainer}>
                    <p><b>
                        ↕<br/>
                        Histoire de se présenter, il faudra écrire ici un bout de texte. Quelques phrases sur notre
                        appétit à
                        questionner les espaces, à écrire des images, à se rencontrer, à fabriquer, à discuter autour
                        d’un café,
                        à marcher dans les friches, à construire des cabanes, à glaner des bribes, à faire, d’une pierre
                        plusieurs ricochets là où nous pourrons engager une culture commune.<br/>
                        ↕
                    </b>
                    </p>
                </div>
            </div>
        )
    }
}